<template>
  <section id="invoices">
    <b-overlay :show="showFilters" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card>
        <b-row>
          <b-col xl="3" md="3">
            <v-select v-model="selectedCompany" label="title" placeholder="Company" :options="allCompanies"
              @input="changeSelectedCompany" />
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
    <b-overlay :show="showInvoices" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Invoices
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-invoices" />
          </h4>
          <b-popover target="popover-invoices" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :per-page="10"
          :current-page="invoicesTable.currentPage" :items="invoicesTable.items" :fields="invoicesTable.fields"
          :sort-by.sync="invoicesTable.sortBy" :sort-desc.sync="invoicesTable.sortDesc"
          :sort-direction="invoicesTable.sortDirection" :filter="invoicesTable.filter"
          :filter-included-fields="invoicesTable.filterOn" @row-clicked="rowClicked" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="invoicesTable.currentPage" :total-rows="invoicesTable.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-modal ref="invoice" title="Invoice Document" cancel-title="Close" cancel-variant="outline-secondary" size="xl"
      :no-close-on-backdrop="true">
      <b-overlay :show="showInvoice" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
        <embed :src="pdfInvoiceFile" width="100%" height="800px">
      </b-overlay>
    </b-modal>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BPopover,
  BRow,
  BCol,
  BOverlay,
  BTable,
  BPagination,
  BModal,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import axios from 'axios';

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BPopover,
    BRow,
    BCol,
    BOverlay,
    BTable,
    BPagination,
    BModal,
    vSelect,
  },
  data() {
    return {
      showFilters: false,
      showInvoices: false,
      showInvoice: false,
      selectedCompany: '',
      allCompanies: [],
      allCompaniesIds: [],
      pdfInvoiceFile: '',
      queryParams: {},
      invoicesTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'documentDate',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'documentUid', label: 'uid', sortable: true },
          { key: 'documentNumber', label: 'number', sortable: true },
          {
            key: 'netAmount',
            label: 'net amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'vat', label: 'vat', sortable: true },
          {
            key: 'grossAmount',
            label: 'gross amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'filename', label: 'filename', sortable: true },
          { key: 'paymentStatus', label: 'payment status', sortable: true },
          { key: 'paymentMethod', label: 'payment method', sortable: true },
          {
            key: 'documentDate',
            label: 'document date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    try {
      await this.getCompanies();
      await this.getInvoices();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getCompanies() {
      this.showFilters = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/companies-gmi/`, {});
        this.allCompanies = response.data.results.map(item => item.name);
        this.allCompaniesIds = response.data.results;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showFilters = false;
      }
    },
    async getInvoices() {
      this.showInvoices = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/invoices-gmi/`, this.queryParams);
        this.invoicesTable.items = response.data.results;
        this.invoicesTable.totalRows = 500;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showInvoices = false;
      }
    },
    async rowClicked(row) {
      this.pdfInvoiceFile = '';
      this.$refs.invoice.show();
      this.showInvoice = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/invoice-gmi/`, { documentUid: row.documentUid });
        this.pdfInvoiceFile = response.data.results;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showInvoice = false;
      }
    },
    async changeSelectedCompany() {
      const selectedCompanyIndex = this.allCompanies.indexOf(this.selectedCompany)
      const selectedCompanyUid = this.allCompaniesIds[selectedCompanyIndex].companyUid
      this.queryParams.companyUid = selectedCompanyUid
      await this.getInvoices()
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
